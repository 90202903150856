import UrlSearchParamsService from "../../urlSearchParamsService";

/**
 * if there's some unsaved form, the function halts the tab change
 * and shows popup to confirm if user really wants to change tabs
 * and lose all the unsaved data
 *
 * @param {function} callback
 * @returns {void}
 */
export default function (callback) {
  let isUnsaved = Boolean(UrlSearchParamsService.get('unsaved'));
  if (!isUnsaved) {
    callback();
    return;
  }

  window.$vm.$modal.show('dialog', {
    title: window.$vm.$t('autoa.user_profile.modal_unsaved.title'),
    text: window.$vm.$t('autoa.user_profile.modal_unsaved.text'),
    buttons: [
      {
        title: window.$vm.$t('autoa.user_profile.modal_unsaved.button_yes'),
        handler: () => {
          window.$vm.$modal.hide('dialog');
          UrlSearchParamsService.removeWithoutHistory('unsaved');
          callback()
        }
      },
      {
        title: window.$vm.$t('autoa.user_profile.modal_unsaved.button_no'),
        handler: () => {
          window.$vm.$modal.hide('dialog');
        }
      }
    ]
  })

}
