import state from './_state';
import getters from './_getters';
import mutations from './_mutations';
import actions from './_actions';

export default {
	state,
	getters,
	mutations,
	actions
};
