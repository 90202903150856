import actions from "./_actions";
import mutations from "./_mutations";
import getters from "./_getters";

export default {
  namespaced: true,

  state: {

  },
  actions,
  mutations,
  getters,
};
