/**
 * Utility class for working with URL search params without need of accessing backend or reloading the page.
 */
export default class UrlSearchParamsService {
  /**
   * @return {URL}
   */
  static getURLObject() {
    return new URL(window.location);
  }

  /**
   * @param {string} key
   * @return {string | null}
   */
  static get(key) {
    return UrlSearchParamsService.getURLObject().searchParams.get(key);
  }

  /**
   * Sets the value of the given key in the URL search params.
   * Note that this method __does not__ reload the page.
   * @param {string} key
   * @param {string} value
   * @return {void}
   */
  static set(key, value) {
    const object = UrlSearchParamsService.getURLObject();
    object.searchParams.set(key, value);
    window.history.pushState(null, '', object.toString());
  }
  static setWithoutHistory(key, value) {
    const object = UrlSearchParamsService.getURLObject();
    object.searchParams.set(key, value);
    window.history.replaceState(null, '', object.toString());
  }

  /**
   * Removes the given key from the URL search params.
   * Note that this method __does not__ reload the page.
   * @param {string} key
   */
  static remove(key) {
    const object = UrlSearchParamsService.getURLObject();
    object.searchParams.delete(key);
    window.history.pushState(null, '', object.toString());
  }

  static removeWithoutHistory(key) {
    const object = UrlSearchParamsService.getURLObject();
    object.searchParams.delete(key);
    window.history.replaceState(null, '', object.toString());
  }
}
