import reverse from "django-reverse";
import Vue from "vue";
import haltRedirect from "./components/core/haltRedirect";

export const TTL_SEC = 1000
export const TTL_MIN = TTL_SEC * 60
export const TTL_HOUR = TTL_MIN * 60
export const TTL_DAY = TTL_HOUR * 24

export function createNameFromVModelExpr(component) {
  // automatically creates name from v-model expression
  const { model } = component.$vnode.data;
  if (model && model.expression) return [model.expression, component._uid].join('-').replace('.', '-');
}

export const EMPTY_CHOICE = null;


export function getCarListUrl() {
  return reverse("cms:user_profile",
    window.$vm.$t("autoa.user_profile.tab_garage")
  );
}

export function getCarDetailUrl(carId, activeTab = null) {
  return `${reverse("cms:user_profile",
    window.$vm.$t("autoa.user_profile.tab_garage"),
    window.$vm.$t("autoa.user_profile.sub_tab_car_detail"),
    carId
  )}${activeTab ? '?active_tab=' + activeTab : ''}`;
}

export function getUserProfileUrl() {
  return reverse("cms:user_profile",
    window.$vm.$t("autoa.user_profile.tab_profile")
  );
}

export function getCompanyListUrl() {
  return reverse("cms:user_profile",
    window.$vm.$t("autoa.user_profile.tab_companies")
  );
}

export function getCompanyDetailUrl(companyId) {
  return reverse("cms:user_profile",
    window.$vm.$t("autoa.user_profile.tab_companies"),
    window.$vm.$t("autoa.company_profile.tab_profile"),
    companyId
  );
}

export function getCarTitle(car) {
  return `${car.type_model.type_make.identifier}, ${car.type_model.description}, ${car.type_model.model_year}`;
}

export function getCarTitleLong(carSale) {
  return `${carSale.car.type_model.type_make.identifier}, ${carSale.car.type_model.description}, ${carSale.car.type_model.model_year} (${carSale.price} Kč)`;
}

export function getCarTitleLongWithCommission(carSale) {
  return `${carSale.car.type_model.type_make.identifier}, ${carSale.car.type_model.description}, ${carSale.car.type_model.model_year} (${carSale.price_with_commission} Kč)`;
}

export function redirectWithHalt(path) {
  haltRedirect(() => {
    window.location.href = path;
  })
}
