const defaultAsyncOptions = {component: null, delay: 200};

const articles = {
  AutoaListArticles: () => ({...defaultAsyncOptions, component: import('./articles/AutoaListArticles/App.vue')}),
  AutoaListNews: () => ({...defaultAsyncOptions, component: import('./AutoaNews/App.vue')}),
  AutoaShare: () => ({...defaultAsyncOptions, component: import('./articles/AutoaShare/App.vue')}),
};

const autoa = {
  AutoaDialog: () => ({...defaultAsyncOptions, component: import('@olc/cms-teresa/src-fe/contrib/vue/CmsDialog.vue')}),

  AutoaFooter: () => ({...defaultAsyncOptions, component: import('./AutoaFooter/App.vue')}),
  AutoaHeader: () => ({...defaultAsyncOptions, component: import('./AutoaHeader/App.vue')}),
  AutoaLogin: () => ({...defaultAsyncOptions, component: import('./AutoaLogin/App.vue')}),
  AutoaRegister: () => ({...defaultAsyncOptions, component: import('./AutoaRegister/App.vue')}),
  AutoaSearch: () => ({...defaultAsyncOptions, component: import('./AutoaSearch/App.vue')}),
  AutoaUserProfile: () => ({...defaultAsyncOptions, component: import('./AutoaUserProfile/App.vue')}),

  AutoaModelContracts: () => ({...defaultAsyncOptions, component: import('./AutoaModelContracts/App.vue')}),

  AutoaCarDetail: () => ({...defaultAsyncOptions, component: import('./cars/CarDetail/App.vue')}),

  AutoaCar: () => ({...defaultAsyncOptions, component: import('./cars/VehiclesSale/Car.vue')}),

  AutoaVehiclesSale: () => ({...defaultAsyncOptions, component: import('./cars/VehiclesSale/App.vue')}),
  AutoaSimilarVehicles: () => ({...defaultAsyncOptions, component: import('./cars/SimilarVehicles/App.vue')}),
  AutoaVehicleSale: () => ({...defaultAsyncOptions, component: import('./cars/VehicleSale/App.vue')}),
  AutoaNewVehicleSale: () => ({...defaultAsyncOptions, component: import('./cars/NewVehicleSale/App.vue')}),
  AutoaVehicleSelectionAssistant: () => ({...defaultAsyncOptions, component: import('./cars/VehicleSelectionAssistant/App.vue')}),
  AutoaAddCar: () => ({...defaultAsyncOptions, component: import('./AutoaUserProfile/Garage/App.vue')}),
  AutoaUserCars: () => ({...defaultAsyncOptions, component: import('./AutoaUserProfile/Garage/UserCars.vue')}),
  AutoaConfirmation: () => ({...defaultAsyncOptions, component: import('./confirmation/Confirmation.vue')}),
  AutoaConfirmationContact: () => ({...defaultAsyncOptions, component: import('./AutoaConfirmationContact/AutoaConfirmationContact.vue')}),
  AutoaCountdown: () => ({...defaultAsyncOptions, component: import('./AutoaCountdown/App.vue')}),
  AutoaSearchResults: () => ({...defaultAsyncOptions, component: import('./AutoaSearchResults/App.vue')}),
  AutoaCarTrade: () => ({...defaultAsyncOptions, component: import('./AutoaCarTrade/App.vue')}),
  AutoaTradeAdmin: () => ({...defaultAsyncOptions, component: import('./AutoaCarTradeAdmin/App.vue')}),
  AutoaContractResignation: () => ({...defaultAsyncOptions, component: import('./AutoaContractResignation/App.vue')}),

  AutoaSalesCenter: () => ({...defaultAsyncOptions, component: import('./AutoaUserProfile/BusinessCenter/SalesCenter')}),
  AutoaStartTrade: () => ({...defaultAsyncOptions, component: import('./AutoaCarTrade/StartTradeComponent.vue')}),
  AutoaJoinUsBanner: () => ({...defaultAsyncOptions, component: import('./cars/VehiclesSale/JoinUsBanner')}),
  ContactUs: () => ({...defaultAsyncOptions, component: import('./ContactUs/App')}),
  AutoaResetPassword: () => ({...defaultAsyncOptions, component: import('./AutoaLogin/ResetPassword')}),
  TradeBackLink: () => ({...defaultAsyncOptions, component: import('./AutoaCarTrade/TradeBacklinkComponent')}),
  AutoaEnlistCar: () => ({...defaultAsyncOptions, component: import('./AutoaEnlistCar/App.vue')}),
};


export default {
  ...articles,
  ...autoa,
};
