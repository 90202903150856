import Vue from "vue";
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import * as Application from "@olc/cms-teresa/src-fe";
import projectComponents from "./components";
import CmsAdminComponents from "@olc/cms-teresa/src-fe/admin/components.js";
import CmsContribComponents from "@olc/cms-teresa/src-fe/contrib/components.js";
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";
import VueSocialSharing from 'vue-social-sharing';


Vue.use(VueSocialSharing);

import cs_messages, {
  numberFormats as cs_numberFormats,
  datetimeFormats as cs_datetimeFormats,
  pluralizationRules as cs_pluralizationRules,
} from "../locales/entries/cs.js";

import {cmsVueLibrary} from "@olc/cms-teresa/src-fe/utils";
import prepareVue from "@olc/cms-teresa/src-fe/vue";
import * as EVENT_BUS_TYPES from "./event-bus/event-types.js";
import storeModules from "./store/modules";
import appGlobalStore from "./store/global";

import axios from "@olc/cms-teresa/src-fe/plugins/axios";

// Template to process Vue renderer
const template = document.getElementById("cms-app");
const templateAdmin = document.getElementById("cms-admin-app");

import VueAppend from 'vue-append';
import VueLocalStorage from "vue-localstorage";
import {Draggable, Fold, Tree} from "he-tree-vue";

/* Vue Awesome Swiper */
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
} from "swiper/js/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/css/swiper.min.css";
import {SHARED_MUTATIONS} from "./store/global/_mutations";
import {getCarTitle, getCarTitleLong, getCarTitleLongWithCommission} from "./utils";

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);

let vm = null;
let i18n = null;
let eventBus = null;

window.CmsVue = prepareVue(Vue, {
  EventBusTypes: {
    ...EVENT_BUS_TYPES,
  },
});

if (template) {
  // Remove all script elements from template, and append them to the body element
  document.body.append(...Array.from(template.getElementsByTagName("script")));

  Vue.config.devtools = window.env.NODE_ENV !== "production";
  Vue.config.silent = window.env.NODE_ENV === "production";
  Vue.config.performance = window.env.NODE_ENV !== "production";

  if (window.env.NODE_ENV !== "development") {
    Sentry.init({
      Vue,
      dsn: "https://b4fac00eb78542a6af1283ac1850ccff@sentry.olc.cz/77",
      integrations: [(new Integrations.BrowserTracing())],
      trackComponents: true,
      release: window.env.VERSION,
      environment: window.env.NODE_ENV,
      tracesSampleRate: window.env.NODE_ENV === "production" ? 0.5 : 1.0,
    });
  }


  Vue.prototype.$range = function* (start, end, step = 1) {
    for (let i = start; i <= end; i += step) {
      yield i
    }
  }

  window.CmsVueObj = {
    Vue: window.CmsVue,
    el: `#${template.getAttribute("id")}`,
    template,
    components: {
      ...CmsContribComponents,
      ...projectComponents,
    },
    libraries: [
      cmsVueLibrary(VueAppend, 'vueAppend'),
      cmsVueLibrary(VueLocalStorage, "vueLocalStorage"),
      cmsVueLibrary(getAwesomeSwiper(SwiperClass), "VueAwesomeSwiper"),
    ],
    appId: template.getAttribute("id"),
    storeModules: {...storeModules},
    storePlugins: [
      createPersistedState({paths: ["lastVisitedProductCodes", "cart"]}),
      createMutationsSharer({predicate: SHARED_MUTATIONS}),
    ],
    appGlobalStore: appGlobalStore,
    i18nOptions: {
      fallbackLanguage: "en",
      messages: {
        cs: cs_messages,
      },
      numberFormats: {...cs_numberFormats},
      datetimeFormats: {...cs_datetimeFormats},
      pluralizationRules: {
        ...cs_pluralizationRules
      }
    },
    vueObjectsFactory: () => {
      return {
        localStorage: {
          cartSelectedUserData: {
            type: Object,
            default: {
              selectedDelivery: null,
              selectedPayment: null,
            },
          },
          recommendedAccessorySelectedAccessoryCategory: {
            type: Object,
            default: {
              selectedAccessoryCategories: [],
              isNegated: false,
            },
          },
        },
      };
    },
    vueProperties: {
      /**
       * Asynchronous api to make http requests. Call this.$http.get(url).then((data) => ... )
       */
      $http: {
        enumerable: false,
        configurable: false,
        writable: false,
        value: axios,
      },
    },
  };
  vm = Application.create(window.CmsVueObj);

  window.$vm = vm;

  i18n = vm.$i18n;
  eventBus = vm.$eventBus;

} else {
  throw new Error("The template is required");
}

if (templateAdmin) {
  window.$vma = Application.create({
    Vue: window.CmsVue,
    el: `#${templateAdmin.getAttribute("id")}`,
    template: templateAdmin,
    components: CmsAdminComponents,
    appId: templateAdmin.getAttribute("id"),
  });
}

Vue.filter('yesno', function (value) {
  return value ? i18n.t('autoa.yes') : i18n.t('autoa.no');
})

Vue.filter('getCarTitle', function (car) {
  return getCarTitle(car)
})

Vue.filter('getCarTitleLong', function (carSale) {
  return getCarTitleLong(carSale)
})

Vue.filter('getCarTitleLongWithCommission', function (carSale) {
  return getCarTitleLongWithCommission(carSale)
})


export {vm, i18n, eventBus};
