import localeAdmin from '@olc/cms-teresa/src-fe/admin/locale/cs.json';
import localeContrib from '@olc/cms-teresa/src-fe/contrib/locale/cs.json';
import localeVForm from '@olc/vforms/src/locale/cs.json';
import localeApp from './../translates/cs.json';

export default {
  ...localeAdmin,
  ...localeContrib,
  ...localeVForm,
  ...localeApp
}

export const datetimeFormats = {
  cs: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }
  },
};

export const numberFormats = {
  cs: {
    currency: {
      style: 'currency', currency: 'CZK', minimumFractionDigits: 0, maximumFractionDigits: 0,
    },
    integer: {
      style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0,
    },
  },
};

export const pluralizationRules = {
  /**
   * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
   * @param choicesLength {number} an overall amount of available choices
   * @returns a final choice index to select plural word by
   */
  cs: function (choice, choicesLength) {
    // this === VueI18n instance, so the locale property also exists here
    if (choice === 0) {
      return 0;
    } else if (choice === 1) {
      return (choicesLength > 1)? 1 : 0;
    }

    if (choice >= 2 && choice <= 4) {
      return (choicesLength >= 3)? 2 : 1;
    }

    if (choice > 4) {
      return (choicesLength >= 4)? 3 : 2;
    }

    return (choicesLength < 4) ? 2 : 3;
  }
}
