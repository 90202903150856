export const CAR_SALE_LOAD_VEHICLES = "CAR_SALE::LOAD_VEHICLES";

export const CAR_DETAIL_GALLERY_YEARS = "CAR_DETAIL::GALLERY_YEARS";
export const CAR_DETAIL_GALLERY_FILTER_YEAR = "CAR_DETAIL::GALLERY_FILTER_YEAR";
export const CAR_DETAIL_GALLERY_REFRESH = "CAR_DETAIL::GALLERY_REFRESH";

export const CAR_DETAIL_SERVICE_HISTORY_YEARS = "CAR_DETAIL::SERVICE_HISTORY_YEARS";
export const CAR_DETAIL_SERVICE_HISTORY_FILTER_YEAR = "CAR_DETAIL::SERVICE_HISTORY_FILTER_YEAR";
export const CAR_DETAIL_SERVICE_HISTORY_REFRESH = "CAR_DETAIL::SERVICE_HISTORY_REFRESH";

export const CAR_ASSISTANT_REFRESH_LOAD_BUTTON = "CAR_ASSISTANT::REFRESH_LOAD_BUTTON";
export const CAR_ASSISTANT_SAVE_FORM_DATA = "CAR_ASSISTANT::SAVE_FORM_DATA";

export const CAR_COMPONENT_PRICE_CHANGED = "CAR_COMPONENT::CHANGED";

